<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-snackbar />

    <core-view />
  </v-app>
</template>

<script>
  import { mapMutations } from 'vuex'
  import authApi from '@/api/auth'

  export default {
    components: {
      CoreAppBar: () => import('@/components/core/CoreAppBar'),
      CoreDrawer: () => import('@/components/core/CoreDrawer'),
      CoreView: () => import('@/components/core/CoreView'),
      CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
    },

    mounted () {
      this.checkLogin()
    },

    data: () => ({ }),

    methods: {
      ...mapMutations({
        setCheckLoginInterval: 'general/SET_CHECK_LOGIN_INTERVAL',
      }),

      checkLogin () {
        this.setCheckLoginInterval(setInterval(async () => {
          await authApi.checkLogin()
        }, (1000 * 60)))
      },

    },

  }
</script>

<style>
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #b0b0b0 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  border: 1px solid #ffffff;
}
</style>
